// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fyDiTgQh5i_nWnsowTGg{display:flex;-moz-column-gap:20px;column-gap:20px}.AQCxoyssM64u2u5IbTfg{width:60%}.Nrh4pLh5LaVbKktOSOLQ{width:40%}.Nrh4pLh5LaVbKktOSOLQ textarea{width:100%;height:220px}.oa40hhePSFqJ39vh_t5z{display:flex;flex-wrap:wrap;justify-content:space-between;-moz-column-gap:10px;column-gap:10px}.G78HEHp6ys4EUUMYCEQE{flex:1;min-width:100px;display:flex;flex-direction:column}.cliBYbRwzMYCG3Emiosy{width:100%}.UuOcRdvZFc0aQICAhHw4{flex-grow:0;flex-shrink:0;flex-basis:200px}.RL_2NJT2RV2HSFDhtf3t{font-weight:bold}.mlgfZQWHt1jd2yGhKEBU{font-size:20px}.yedPGnlsaV6jUc5p0t0Q{padding-left:15px}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/ServicesTab/components/VaultSetupForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oBAAA,CAAA,eAAA,CACF,sBACE,SAAA,CACF,sBACE,SAAA,CACA,+BACE,UAAA,CACA,YAAA,CACJ,sBACE,YAAA,CACA,cAAA,CACA,6BAAA,CACA,oBAAA,CAAA,eAAA,CACF,sBACE,MAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACF,sBACE,UAAA,CACF,sBACE,WAAA,CACA,aAAA,CACA,gBAAA,CACF,sBACE,gBAAA,CACF,sBACE,cAAA,CACF,sBACE,iBAAA","sourcesContent":[".formColumns\n  display: flex\n  column-gap: 20px\n.mainColumn\n  width: 60%\n.otherColumn\n  width: 40%\n  textarea\n    width: 100%\n    height: 220px\n.flexRow\n  display: flex\n  flex-wrap: wrap\n  justify-content: space-between\n  column-gap: 10px\n.inputField\n  flex: 1\n  min-width: 100px\n  display: flex\n  flex-direction: column\n.input\n  width: 100%\n.inputPath\n  flex-grow: 0\n  flex-shrink: 0\n  flex-basis: 200px\n.mainCheckbox\n  font-weight: bold\n.title\n  font-size: 20px\n.content\n  padding-left: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formColumns": `fyDiTgQh5i_nWnsowTGg`,
	"mainColumn": `AQCxoyssM64u2u5IbTfg`,
	"otherColumn": `Nrh4pLh5LaVbKktOSOLQ`,
	"flexRow": `oa40hhePSFqJ39vh_t5z`,
	"inputField": `G78HEHp6ys4EUUMYCEQE`,
	"input": `cliBYbRwzMYCG3Emiosy`,
	"inputPath": `UuOcRdvZFc0aQICAhHw4`,
	"mainCheckbox": `RL_2NJT2RV2HSFDhtf3t`,
	"title": `mlgfZQWHt1jd2yGhKEBU`,
	"content": `yedPGnlsaV6jUc5p0t0Q`
};
export default ___CSS_LOADER_EXPORT___;
