// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdMub4sfFnHduQKLS_vA{display:flex;justify-content:space-between;align-items:center;padding:10px 24px;border-radius:0 !important}.JICRgacx4YVJYJIdGDgW{font-size:12px;text-align:right;font-weight:bold;color:#000}`, "",{"version":3,"sources":["webpack://./src/pages/GenerateAiMetadata/pages/RegisterGenerateAiMetadataBatch/components/SelectWorksData/components/WorkList/components/WorkCard/component/Footer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,0BAAA,CACF,sBACE,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".footer\n  display: flex\n  justify-content: space-between\n  align-items: center\n  padding: 10px 24px\n  border-radius: 0 !important\n.info\n  font-size: 12px\n  text-align: right\n  font-weight: bold\n  color: #000\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `mdMub4sfFnHduQKLS_vA`,
	"info": `JICRgacx4YVJYJIdGDgW`
};
export default ___CSS_LOADER_EXPORT___;
