// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RqX929jo4FEfEbY8FcaP{font-size:13px}`, "",{"version":3,"sources":["webpack://./src/pages/GenerateAiMetadata/pages/GenerateAiMetadataBatch/components/AcquireLicenseForm/components/GenerateEmbeddingForm/components/EstimatedCosts/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".estimateCost\n  font-size: 13px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"estimateCost": `RqX929jo4FEfEbY8FcaP`
};
export default ___CSS_LOADER_EXPORT___;
