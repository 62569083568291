// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RJ8cNkbEMorUuH7bFc2Y{display:flex;justify-content:space-between;align-items:flex-start;padding:0px 24px}.Fwg1M1BiPmlXYbyFMNmQ{display:flex;flex-direction:column;align-items:flex-start;gap:4px}.Oy4nLfKkBn1xC_qSKKYw{border-radius:0 !important}.Oy4nLfKkBn1xC_qSKKYw._L9KBDqmA6zIgqY1V0SH{height:25px !important;width:110px !important}.IMSaYO83U04dEecK91N9{display:flex;flex-direction:column}.Nc5Mz1ZScFIy4t22ufg9{font-size:13px;line-height:1.2}.vSAsd1MROse1xrnbmDJX{font-weight:bold}.FNSDs85ZEYbmptMLdtLl{font-weight:bold}.tjnA_Pxo6iSexY5vwkkG{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/components/cards/SamCard/components/SamFooter/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,gBAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CAEF,sBACE,0BAAA,CACA,2CACE,sBAAA,CACA,sBAAA,CAEJ,sBACE,YAAA,CACA,qBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,gBAAA","sourcesContent":[".footer\n  display: flex\n  justify-content: space-between\n  align-items: flex-start\n  padding: 0px 24px\n\n.info\n  display: flex\n  flex-direction: column\n  align-items: flex-start\n  gap: 4px\n\n.button\n  border-radius: 0 !important\n  &.cardAsLink\n    height: 25px !important\n    width: 110px !important\n\n.item\n  display: flex\n  flex-direction: column\n\n.combined\n  font-size: 13px\n  line-height: 1.2\n\n.label\n  font-weight: bold\n\n.value\n  font-weight: bold\n\n.valueId\n  font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `RJ8cNkbEMorUuH7bFc2Y`,
	"info": `Fwg1M1BiPmlXYbyFMNmQ`,
	"button": `Oy4nLfKkBn1xC_qSKKYw`,
	"cardAsLink": `_L9KBDqmA6zIgqY1V0SH`,
	"item": `IMSaYO83U04dEecK91N9`,
	"combined": `Nc5Mz1ZScFIy4t22ufg9`,
	"label": `vSAsd1MROse1xrnbmDJX`,
	"value": `FNSDs85ZEYbmptMLdtLl`,
	"valueId": `tjnA_Pxo6iSexY5vwkkG`
};
export default ___CSS_LOADER_EXPORT___;
