// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wv_05_jQVsVb23fvYb4R{max-width:400px;width:100%;margin-left:auto;margin-right:auto}.eTPLQNtzNwVDjyA_C8w9{padding-bottom:15px;margin-top:15px;border-bottom:1px solid #ddd}.NA1h7fMGPP7D4m6CDLDk{font-weight:bold}.eTPLQNtzNwVDjyA_C8w9:first-child{margin-top:0}.eTPLQNtzNwVDjyA_C8w9:last-child{border:none}`, "",{"version":3,"sources":["webpack://./src/pages/ManageSams/pages/Sam/components/Modal/components/SamList/styles.modules.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,mBAAA,CACA,eAAA,CACA,4BAAA,CACF,sBACE,gBAAA,CACF,kCACE,YAAA,CACF,iCACE,WAAA","sourcesContent":[".samList\n  max-width: 400px\n  width: 100%\n  margin-left: auto\n  margin-right: auto\n.item\n  padding-bottom: 15px\n  margin-top: 15px\n  border-bottom: 1px solid #ddd\n.id\n  font-weight: bold\n.item:first-child\n  margin-top: 0\n.item:last-child\n  border: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"samList": `Wv_05_jQVsVb23fvYb4R`,
	"item": `eTPLQNtzNwVDjyA_C8w9`,
	"id": `NA1h7fMGPP7D4m6CDLDk`
};
export default ___CSS_LOADER_EXPORT___;
