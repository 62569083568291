// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ay5Czqbh8kMDu32iLXAU{padding:0 24px;font-size:13px}.BiINT3_yohjdy4TXDnHh{text-transform:capitalize;font-weight:bold}.jjyTintd7eeKKWMpbJYz{font-weight:bold;color:#90959f}.cvJfPZ135z72WC2NdtVT{font-weight:bold;color:#acacb6}.XtFRND5lghHTLrVnejBw{margin-bottom:4px}`, "",{"version":3,"sources":["webpack://./src/components/cards/SamCard/components/SamBody/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,cAAA,CACF,sBACE,yBAAA,CACA,gBAAA,CACF,sBACE,gBAAA,CACA,aAAA,CACF,sBACE,gBAAA,CACA,aAAA,CACF,sBACE,iBAAA","sourcesContent":[".body\n  padding: 0 24px\n  font-size: 13px\n.author\n  text-transform: capitalize\n  font-weight: bold\n.colorPublisher\n  font-weight: bold\n  color: #90959f\n.colorBody\n  font-weight: bold\n  color: #acacb6\n.mb-sm\n  margin-bottom: 4px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `ay5Czqbh8kMDu32iLXAU`,
	"author": `BiINT3_yohjdy4TXDnHh`,
	"colorPublisher": `jjyTintd7eeKKWMpbJYz`,
	"colorBody": `cvJfPZ135z72WC2NdtVT`,
	"mb-sm": `XtFRND5lghHTLrVnejBw`
};
export default ___CSS_LOADER_EXPORT___;
