// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BVJHGE2SE9zBEDXutnQA{cursor:pointer}.vN9viqx3THspoRKr56MQ{position:relative;display:inline-block}.TuGQ3_o18UT1DmEJXUYS{text-transform:uppercase;color:#fff;text-align:center;min-width:100px;height:40px;position:absolute;z-index:5;top:0;left:0;background:#000;font-size:20px;display:flex;align-items:center;justify-content:center;padding:5px 5px;font-weight:bold}.FQK02o48tP0gIZ7LWXxf{position:absolute;z-index:10;top:10px;right:10px}.wUY6oKHiJUZG1LEp2dGs{background:#fff;border-radius:100%}`, "",{"version":3,"sources":["webpack://./src/components/cards/SamCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACF,sBACE,iBAAA,CACA,oBAAA,CACF,sBACE,wBAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CACA,KAAA,CACA,MAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CACF,sBACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,UAAA,CACF,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".isLink\n  cursor: pointer\n.card\n  position: relative\n  display: inline-block\n.classification\n  text-transform: uppercase\n  color: #fff\n  text-align: center\n  min-width: 100px\n  height: 40px\n  position: absolute\n  z-index: 5\n  top: 0\n  left: 0\n  background: #000\n  font-size: 20px\n  display: flex\n  align-items: center\n  justify-content: center\n  padding: 5px 5px\n  font-weight: bold\n.radio\n  position: absolute\n  z-index: 10\n  top: 10px\n  right: 10px\n.circle\n  background: #fff\n  border-radius: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isLink": `BVJHGE2SE9zBEDXutnQA`,
	"card": `vN9viqx3THspoRKr56MQ`,
	"classification": `TuGQ3_o18UT1DmEJXUYS`,
	"radio": `FQK02o48tP0gIZ7LWXxf`,
	"circle": `wUY6oKHiJUZG1LEp2dGs`
};
export default ___CSS_LOADER_EXPORT___;
