// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eHXPYmSQXxJ31nIqtisW{background:#fff;border:1px solid rgba(172,172,172,0.1411764706);padding:.5rem 1rem;border-radius:2px;height:40px;width:100%;margin-right:10px}.HyIrRMKI9cY7_MdMRydW{display:flex;align-items:center;margin-bottom:20px}.Mn7gyV83TnSuanZhpYuz{font-weight:bold}.bK0PYYM5IAEfJL24Ceew{display:flex}.EkN5x2f4YIXEPkXilSK7{max-height:150px;overflow-y:auto}.a2ZJdVsLrjKWXMGiFWf5{display:flex}`, "",{"version":3,"sources":["webpack://./src/pages/GenerateAiMetadata/pages/GenerateAiMetadataBatch/components/AcquireLicenseForm/components/GenerateEmbeddingForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,+CAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACF,sBACE,gBAAA,CACF,sBACE,YAAA,CACF,sBACE,gBAAA,CACA,eAAA,CACF,sBACE,YAAA","sourcesContent":[".select\n  background: #fff\n  border: 1px solid rgba(172,172,172,.1411764706)\n  padding: .5rem 1rem\n  border-radius: 2px\n  height: 40px\n  width: 100%\n  margin-right: 10px\n.checkBox\n  display: flex\n  align-items: center\n  margin-bottom: 20px\n.totalEstimateCost\n  font-weight: bold\n.embeddingCheckBox\n  display: flex\n.inputCheckbox\n  max-height: 150px\n  overflow-y: auto\n.inputSelect\n  display: flex\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `eHXPYmSQXxJ31nIqtisW`,
	"checkBox": `HyIrRMKI9cY7_MdMRydW`,
	"totalEstimateCost": `Mn7gyV83TnSuanZhpYuz`,
	"embeddingCheckBox": `bK0PYYM5IAEfJL24Ceew`,
	"inputCheckbox": `EkN5x2f4YIXEPkXilSK7`,
	"inputSelect": `a2ZJdVsLrjKWXMGiFWf5`
};
export default ___CSS_LOADER_EXPORT___;
