// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usjHFqXwGrxsHhmkbgrP{max-width:300px !important;font-size:10px;line-height:1}.fu3ij3L9qhSBZjzeYKTk{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/GenerateAiMetadata/pages/GenerateAiMetadataBatch/components/AcquireLicenseForm/components/GenerateEmbeddingForm/components/InfoToolTip/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,cAAA,CACA,aAAA,CACF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".tooltip\n  max-width: 300px !important\n  font-size: 10px\n  line-height: 1\n.iconContainer\n  display: flex\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `usjHFqXwGrxsHhmkbgrP`,
	"iconContainer": `fu3ij3L9qhSBZjzeYKTk`
};
export default ___CSS_LOADER_EXPORT___;
